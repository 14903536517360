import { Observable } from 'rxjs/Observable';
import { paymentService } from 'services/PaymentService';
import { cartService } from 'services/CartService';
import { invoiceService } from './InvoiceService';
import * as queryString from 'query-string';
import { IPaymentResult } from 'interfaces/IPaymentResult';
import { IProduct, ISubscription, IContent } from 'interfaces/IProductData';
import { parameterService } from 'services/ParameterService';
import { apiService } from 'shared/services';
import { showError } from 'shared/services/dialog';
import { getCdnImage, formatScripts } from 'functions';
import { enProductType } from 'enums/enProductType';
import { enParameters } from 'enums/enParameters';
import { scriptsService } from 'services/ScriptsService';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { IPixelModel } from 'interfaces/IPixelModel';
import { enPaymentType } from 'enums/enPaymentType';
import { pixelService, enPixelPath } from 'services/PixelService';
import { legacyPixelService, enLegacyPixelPath } from 'services/LegacyPixelService';
import { IUserAddressModel } from 'interfaces/IUserAddressModel';
import { currencyService } from './CurrencyService';
import { enCurrency } from 'enums/enCurrency';
import { isInternationalCurrency } from 'shared/utils/isInternationalCurrency';
import { symbolUrl } from 'shared/utils/symbolUrl';

interface ICliendData {
  name: string;
  phone: string;
  document?: string;
  email: string;
  address?: IUserAddressModel;
}

export interface IThankYou {
  affiliateId?: number;
  campaign: string;
  hasThankyouUpsell: boolean;
  content: IContent;
  contentId: string;
  currency: enCurrency;
  installments: number;
  logo: string;
  pagDivididoInstallmentInfo: string;
  paid: number;
  paymentResult: IPaymentResult;
  paymentType: enPaymentType;
  pixelContentId: string;
  producerId: number;
  products: IProduct[];
  productType: enProductType;
  scripts: any;
  studdent?: ICliendData;
  subscription?: ISubscription;
  status?: number;
  user: ICliendData;
  isContractSaleRecovery?: boolean;
}

export class ThankYouService {
  public getThankYou(): Observable<IThankYou> {
    return paymentService
      .getPaymentResult()
      .filter(() => paymentService.hasPayment())
      .filter(() => !this.hasBankslipParameter())
      .combineLatest(cartService.getCart(), paymentService.getCart())
      .switchMap(([paymentResult, cart, payment]) => {
        let numberInstallments = payment.payment[0].numberInstallments || cart.installments[0].installment;

        if (
          [enPaymentMethod.BANKSLIP, enPaymentMethod.PIX, enPaymentMethod.PAYPAL, enPaymentMethod.PAGDIVIDIDO].includes(
            paymentResult.paymentMethod
          ) ||
          isInternationalCurrency(cart.config.currency)
        ) {
          numberInstallments = 1;
        }

        const studdent =
          payment.studdent && payment.studdent.name
            ? {
                name: payment.studdent.name,
                email: payment.studdent.email,
                document: payment.studdent.document,
                phone: payment.studdent.phone
              }
            : undefined;
        const hascContractId = !!cart.contractId;
        const isContractSaleRecovery = hascContractId ?? false;

        return Observable.of({
          paymentResult,
          isContractSaleRecovery,
          products: cart.products,
          logo: getCdnImage(cart.config.logo),
          productType: cart.config.productType,
          pagDivididoInstallmentInfo: paymentResult.pagDivididoInstallmentInfo,
          installments: numberInstallments,
          paid: paymentResult.paidAmount,
          currency: cart.config.currency,
          scripts: cart.config.scripts,
          content: cart.content,
          user: {
            name: payment.name,
            email: payment.email,
            phone: payment.phone,
            address: payment.address || undefined
          },
          studdent,
          subscription: cart.subscription || undefined
        } as IThankYou);
      })
      .first();
  }

  getThankyouByHash(transactionKey: string): Observable<IThankYou> {
    return Observable.of(paymentService.hasPayment())
      .switchMap(() => parameterService.getAll().take(1))
      .switchMap((parameters) => apiService.get<IThankYou>(`/thankyou2/${transactionKey}`, parameters).loader().logError())
      .map((thankyou) => {
        currencyService.setAvailableCurrencies([thankyou.currency]);
        currencyService.setCurrency(thankyou.currency);
        if (!!thankyou.scripts && !parameterService.getSync(enParameters.bs)) {
          const thankyouScripts = formatScripts(thankyou.scripts);
          scriptsService.setScripts(thankyouScripts);
        }

        if (thankyou.paymentResult.paymentMethod === enPaymentMethod.PAYPAL && pixelService.hasCookie(thankyou.contentId)) {
          let model: IPixelModel = {
            contentId: thankyou.contentId,
            parentId: thankyou.pixelContentId,
            producerId: thankyou.producerId,
            affiliateId: thankyou.affiliateId || null
          };

          model.user = {
            name: thankyou.user.name || '',
            email: thankyou.user.email || '',
            phone: thankyou.user.phone || ''
          };

          model.payment = {
            product: thankyou.content.title,
            price: thankyou.paid,
            quantity: thankyou.paymentResult.amount || 1,
            currency: thankyou.currency,
            paymentType: thankyou.paymentType,
            normalizedPaymentType: thankyou.paymentResult.paymentMethod,
            transactionId: transactionKey,

            thankyouUrl: thankyou.paymentResult.thankyouUrl || null,
            campaign: thankyou.campaign || null,
            subscription: thankyou.subscription || null
          };

          pixelService.setPixel(model);
          /*
            legacyPixelService: Should be removed in not too distance future
          */
          legacyPixelService.track(enLegacyPixelPath.THANKYOU);

          pixelService.setPath(enPixelPath.CREDITCARD);
        }

        return {
          ...thankyou,
          logo: getCdnImage(thankyou.logo)
        };
      })
      .catch((err) => {
        return showError(err).switchMap(() => Observable.empty<IThankYou>());
      });
  }

  public getPostBack(): Observable<any> {
    return paymentService
      .getPaymentResult()
      .filter(() => paymentService.hasPayment())
      .switchMap((result) => {
        return Observable.combineLatest(Observable.of(result), invoiceService.create(result.key).retry(20).logError());
      })
      .filter(() => !this.hasBankslipParameter())
      .filter(([result]) => !!result.thankyouUrl)
      .first();
  }

  public redirectThankYou(result: IPaymentResult) {
    if (this.isBetaInstantRedirect(result.producerId) && result.thankyouUrl) {
      const url = `${result.thankyouUrl}${symbolUrl(result.thankyouUrl)}transactionKey=${result.key}`;
      Observable.never().loader().subscribe();
      window.location.href = this.createPixelHash(url);
      return null;
    }

    if (result.redirectTo) {
      Observable.never().loader().subscribe();

      window.location.href = result.redirectTo;
      return null;
    }

    return `/thankyou/${result.contentId}/${result.key}`;
  }

  private createPixelHash(url: string) {
    return `${url}${symbolUrl(url)}hash=${window.btoa(document.location.hostname)}&ext=0`;
  }

  private isBetaInstantRedirect(producerId: number): boolean {
    const producersInBetaInstantRedirect = [58262598, 41084420, 72061380];

    return producersInBetaInstantRedirect.includes(producerId);
  }

  private hasBankslipParameter() {
    const qsParameters = queryString.parse(window.location.search);
    return !!qsParameters['bs'];
  }
}

// tslint:disable-next-line:variable-name
export const ThankYouServiceFactory = () => new ThankYouService();

export const thankYouService = ThankYouServiceFactory();
