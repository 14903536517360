import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { CLOUDFRONT_DOMAIN, FINGERPRINT_API_KEY, FPJS_AGENT_DOWNLOAD_PATH, FPJS_BEHAVIOR_PATH, FPJS_GET_RESULT_PATH, IS_DEVELOPMENT } from 'settings';
import { v4 } from 'uuid';
import { sleep } from '../functions';
import * as Sentry from '@sentry/browser';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { apiService } from 'shared/services';

const FINGERPRINT_SCRIPT_URL = `${CLOUDFRONT_DOMAIN}/${FPJS_BEHAVIOR_PATH}/${FPJS_AGENT_DOWNLOAD_PATH}?apiKey=${FINGERPRINT_API_KEY}`;
const FINGERPRINT_ENDPOINT_URL = `${CLOUDFRONT_DOMAIN}/${FPJS_BEHAVIOR_PATH}/${FPJS_GET_RESULT_PATH}?region=us`;

interface IFingerprintJS {
  get: Function;
}

type IFingerprintData = {
  fingerprint?: string;
  fingerprintBotData?: string;
};

class FingerprintService {
  private fingerprintData$ = new BehaviorSubject<IFingerprintData>({});
  private creating = false;

  public getFingerprint(paymentMethod: enPaymentMethod) {
    const currentFingerprint = this.fingerprintData$.value;

    let observable = this.fingerprintData$.asObservable();

    const params = new URLSearchParams(window.location.search);
    const enableFingerprintParam = params.get('enableFingerprint');
    const fingerprintPaymentAllowed = [enPaymentMethod.CREDIT_CARD, enPaymentMethod.PIX, enPaymentMethod.BANKSLIP, enPaymentMethod.ONE_CLICK_BUY];

    if (!currentFingerprint.fingerprint && fingerprintPaymentAllowed.includes(paymentMethod) && (!IS_DEVELOPMENT || !!enableFingerprintParam)) {
      return this.fingerprintData$
        .filter((fingerprint) => !fingerprint.fingerprint)
        .switchMap(() => {
          return fromPromise(this.createFingerprint());
        });
    }

    return observable.take(1);
  }

  private async createFingerprint() {
    let fingerprintData: IFingerprintData = {};
    if (this.creating) {
      return fingerprintData;
    }
    this.creating = true;
    let fp: IFingerprintJS = null;
    const generateFingerprint = () => {
      return new Promise<IFingerprintJS>(async (resolve, reject) => {
        try {
          const params = new URLSearchParams(window.location.search);
          const enableFingerprintFailed = params.get('enableFingerprintFailed');

          if (IS_DEVELOPMENT && Boolean(enableFingerprintFailed)) {
            return reject(new Error('Fingerprint generation intentionally failed'));
          }

          const fingerprintJS = await import(/* webpackIgnore: true */ FINGERPRINT_SCRIPT_URL);

          const fp = await fingerprintJS.load({
            endpoint: [
              FINGERPRINT_ENDPOINT_URL,
              fingerprintJS.defaultEndpoint // Fallback to default endpoint in case of error
            ]
          });

          resolve(fp);
        } catch (err) {
          reject(new Error(err));
        }
      });
    };

    const generator = async () => {
      try {
        fp = await generateFingerprint();
        const { visitorId, requestId } = await fp.get();

        const botData = await getBotDetections(requestId);
        const fingerprintData: IFingerprintData = {
          fingerprint: visitorId,
          fingerprintBotData: botData?.data?.bot?.result || undefined
        };
        return fingerprintData;
      } catch (err) {
        await sleep(2000);
        Sentry.captureException(err);
        throw err;
      }
    };

    const getBotDetections = async (requestId: string) => {
      try {
        const responseBotData = await apiService.get(`${window.location.origin}/fingerprint/${requestId}`).toPromise();

        return responseBotData;
      } catch (err) {
        Sentry.captureException(err);
      }
    };

    try {
      fingerprintData = await generator();
    } catch (e) {
      fingerprintData = { fingerprint: 'failed-' + v4() };
    }

    this.fingerprintData$.next(fingerprintData);
    return fingerprintData;
  }
}

export const fingerprintService = new FingerprintService();
