import { Button, Modal } from '@material-ui/core';
import { enStatusWaiter } from 'enums/enStatusWaiter';
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { IWaiter, IResultWaiter, paymentWaiter } from 'services/PaymentWaiterService';
import { useStyles } from './styles';
import terminalService from 'services/TerminalService';
import PaymentLoader from 'components/Cart/PaymentButton/PaymenteMessage/PaymentInProgress/PaymentLoader';
import Translate from 'components/Translate';
interface IProps {
  waiter: IWaiter & IResultWaiter;
}

const TerminalModal: React.FC<IProps> = ({ waiter }) => {
  const styles = useStyles({});
  const [timeLeft, setTimeLeft] = useState('00:00');
  const inProgress = waiter.status === enStatusWaiter.WAITING || waiter.status === enStatusWaiter.RUNNING;

  const cancelPayment = () => {
    terminalService.cancel(waiter.cancelUrl, waiter.result.key).subscribe(() => paymentWaiter.changeWaiterStatus(enStatusWaiter.CANCELED));
  };

  useEffect(() => {
    if (!waiter || !waiter?.waiting) {
      return () => false;
    }

    const interval = setInterval(() => {
      const now = new Date().getTime();

      const diff = Math.floor((waiter.expiresAt - now) / 1000);

      if (diff < 0) {
        setTimeLeft('00:00');
        return;
      }

      const minutes = Math.floor(diff / 60);
      const seconds = diff % 60;

      setTimeLeft(`${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}`);
    }, 1000);

    return () => clearInterval(interval);
  }, [waiter]);

  return (
    <Modal
      aria-labelledby="Modal de pagamento via terminal "
      aria-roledescription="Modal de pagamento via terminal (maquininha) "
      open={true}
      style={{ zIndex: 1 }}
      id="terminal-modal-id"
    >
      <div className={styles.component}>
        <div className={styles.modalContent}>
          {inProgress && <div className={styles.timerHead}>{timeLeft}</div>}
          {waiter.status === enStatusWaiter.PAID && (
            <div id="terminal-complete-panel" className={styles.completeContainer}>
              <CheckCircleIcon className={styles.iconComplete} />
            </div>
          )}
          {waiter.status !== enStatusWaiter.PAID && (
            <div style={{ flex: 1 }}>
              <PaymentLoader />
              <div className={styles.progressMessage}>
                <Translate term="Conclua seu pagamento na maquininha..."></Translate>
              </div>
            </div>
          )}
          {inProgress && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button onClick={cancelPayment} color="secondary" size="medium" style={{ background: '#3960A3', color: '#FFF', padding: '4px 16px' }}>
                Cancelar a compra
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(TerminalModal);
