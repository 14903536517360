import React, { memo } from 'react';
import { SvgIcon } from 'components/SvgIcon';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { IThankYou } from '../../../../services/ThankYouService';
import Translate from 'components/Translate';
import { enThakyouStatus } from 'enums/enThakyouStatus';

interface IProps {
  thankYou: IThankYou;
}

export const Header = memo((props: IProps) => {
  const thankYou = props.thankYou;
  const paymentStatus = thankYou?.status || thankYou?.paymentResult?.status;
  const inAnalyse = paymentStatus === enThakyouStatus.ANALYSING;
  const isSaleRecoveryPaidOrPix =
    thankYou.isContractSaleRecovery && (paymentStatus === enThakyouStatus.PAID || thankYou.paymentResult.paymentMethod === enPaymentMethod.PIX);
  const isSaleRecoveryBankslip = thankYou.isContractSaleRecovery && thankYou.paymentResult.paymentMethod === enPaymentMethod.BANKSLIP;

  const getMessage = (inAnalyse: boolean, thankyou: IThankYou) => {
    if (inAnalyse) {
      return { title: 'Estamos analisando sua compra', subtitle: 'Sua compra não foi efetuada ainda, aguarde para mais informações' };
    }

    if (isSaleRecoveryPaidOrPix) {
      return { title: 'Tudo pronto!', subtitle: 'Sua transação foi efetivada com sucesso.' };
    }
    if (isSaleRecoveryBankslip) {
      return { title: 'Seu boleto está pronto!', subtitle: 'Realize o pagamento para continuar com acesso ao seus produtos' };
    }

    switch (thankyou.paymentResult.paymentMethod) {
      case enPaymentMethod.BANKSLIP:
        return { title: 'Seu boleto está pronto!', subtitle: 'Agora é só pagar seu boleto para receber o produto' };

      case enPaymentMethod.FREE:
        return { title: 'Nós amamos produtos grátis!', subtitle: 'Estamos preparando tudo para você' };

      default:
        return { title: 'Excelente Compra!', subtitle: 'Confirme os dados da sua compra' };
    }
  };

  const message = getMessage(inAnalyse, thankYou);

  return (
    <div className="header" style={inAnalyse ? { backgroundColor: '#F59211' } : null}>
      <div className="icon">{inAnalyse ? <SvgIcon name="alertIcon" /> : <SvgIcon name="check" />}</div>
      <div>
        <h2 id="title-thankyou">
          <Translate term={message.title} />
        </h2>
        <h3 id="subtitle-thankyou">
          <Translate term={message.subtitle} />
        </h3>
      </div>
    </div>
  );
});
