/* eslint-disable max-lines */
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  '@global': {
    '@keyframes pixComplete': {
      '0%': {
        transform: 'scale(0.1) rotate(-45deg)'
      },
      '50%': {
        transform: 'scale(1.3) rotate(0deg)'
      },
      '70%': {
        transform: 'scale(0.8) rotate(0deg)'
      },
      '100%': {
        transform: 'scale(1) rotate(0deg)'
      }
    }
  },

  component: {
    // background: rgba(0, 0, 0, 0.3),
    // position: 'fixed',
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
    // width: '100vw',
    // height: '100vh',
    // zIndex: 10,
    '& .chk-pix-panel': {
      '& .chk-payment-painel': {
        inset: 0,
        margin: '50px auto',
        display: 'flex',
        opacity: 1,
        zIndex: 12,
        position: 'fixed',
        maxWidth: '650px',
        boxShadow: '1px 1px 3px 1px rgba(156, 156, 156, 0.3)',
        maxHeight: '500px',
        alignItems: 'center',
        paddingBottom: '50px',
        justifyContent: 'center',
        backgroundColor: '#fff',
        '&.pix': {
          zIndex: 50
        }
      }
    },

    '& .pixComplete': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%'
    },

    '& .closeArea': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '16px',
      marginTop: '16px',
      marginLeft: 'auto',
      marginBottom: 'auto',
      color: '#C4C4C4',
      fill: '#C4C4C4',
      cursor: 'pointer',
      '& svg': {
        width: '25px',
        marginRight: '4px'
      }
    },

    '& .pixLink, & .purchaseValue': {
      color: '#0D47A1',
      lineHeight: '40px',
      fontSize: '16px',
      fontWeight: 600,
      marginBottom: 'auto'
    },

    '& .pixLink': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',

      '& svg': {
        marginRight: '5px'
      }
    },

    '& .chk-pix-data': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      width: '100%'
    },

    '& .timeLeft': {
      fontSize: '12px'
    },

    '& #pix-panel .chk-payment-painel.pix': {
      '@media only screen and (min-width: 960px)': {
        '&': {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }
    },

    '& #pix-panel .chk-payment-painel.pix.chk-pix-modal-container': {
      background: 'white',
      boxShadow: '1px 1px 3px 1px rgb(156 156 156 / 30%)'
    },

    '& .chk-pix-panel .chk-payment-painel.pix.chk-pix-modal-container': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',

      '&#pix-complete-panel': {
        justifyContent: 'center',
        alignItems: 'center'
      },

      '&:not(.chk-pix-modal-fit-content)': {
        minWidth: 700,
        minHeight: '80%',
        height: 'auto'
      },

      '&.chk-pix-modal-fit-content': {
        '& .chk-pix-instructions-container-qrcode': {
          flexDirection: 'column-reverse',

          '& .chk-pix-qr-code-container': {
            margin: '0 0 24px'
          }
        }
      },

      '&': {
        paddingBottom: 0
      },

      '@media only screen and (min-width: 960px) and (min-height: 750px)': {
        '&:not(.chk-pix-modal-fit-content)': {
          minHeight: 700
        }
      },

      '@media only screen and (max-width: 960px)': {
        '&, &:not(.chk-pix-modal-fit-content)': {
          minHeight: 'initial',
          minWidth: 'initial',
          height: '100%'
        }
      }
    },

    '&& .chk-pix-panel .chk-payment-painel.chk-pix-expired-panel-modal': {
      minHeight: '80%',
      height: 'auto',
      paddingBottom: 0,
      overflowY: 'auto',

      '&#expired-panel-modal-template-14, &#expired-panel-modal-template-16': {
        height: '100%'
      },

      '@media only screen and (min-width: 960px) and (min-height: 680px)': {
        '&#expired-panel-modal-template-18': {
          minHeight: 630
        }
      }
    },

    '& .chk-pix-modal-content.chk-pix-expired-panel': {
      padding: 24,
      height: '100%',

      '& .chk-pix-modal-title': {
        textAlign: 'center'
      },

      '& svg': {
        width: '100%',
        height: 195,
        margin: '16px auto'
      },

      '@media only screen and (max-width: 960px)': {
        padding: 16
      }
    },

    '& .chk-pix-expired-panel-text': {
      margin: '0 auto 16px',
      maxWidth: 450,

      '& span': {
        fontWeight: 'bold',
        color: '#0D47A1'
      }
    },

    '& .chk-pix-timer-container': {
      width: '100%',
      padding: '12px 0',
      textAlign: 'center',
      fontWeight: 700,
      backgroundColor: '#ECEFF1',

      '& span': {
        color: '#0D47A1'
      }
    },

    '& .chk-pix-modal-content': {
      padding: 16,
      color: '#37474F',
      fontSize: 16,
      overflowY: 'auto',
      width: '100%'
    },

    '& .chk-pix-modal-title': {
      margin: 0,
      fontSize: 20,

      '&:first-child': {
        padding: '0 14px'
      },

      '@media only screen and (max-width: 960px)': {
        fontSize: 18,

        '&:first-child': {
          padding: 0
        }
      }
    },

    '& .chk-pix-product-container': {
      display: 'flex',
      alignItems: 'center',
      margin: '16px 0 24px',

      '& .chk-pix-product-text': {
        padding: '0 14px',

        '@media only screen and (max-width: 960px)': {
          padding: 14
        },

        '& span': {
          fontWeight: 700,
          color: '#4CAF50'
        }
      },

      '& img': {
        width: 64,
        height: 64,
        objectFit: 'cover',
        borderRadius: 4
      }
    },

    '& .chk-pix-instructions-list': {
      paddingLeft: 16,
      listStyle: 'decimal',
      marginBottom: 0,

      '@media only screen and (max-width: 960px)': {
        marginBottom: 16
      },

      '& li': {
        marginBottom: 16,
        '&::marker': {
          fontWeight: 700
        },

        '&:last-child': {
          marginBottom: 0
        },

        '@media only screen and (max-width: 960px)': {
          '&:first-child': {
            marginBottom: 0
          }
        }
      },

      '& .chk-pix-instructions-code': {
        marginLeft: -16,
        padding: '16px 0 24px'
      }
    },

    '& .chk-pix-panel .chk-pix-code-input': {
      padding: '12px !important',
      borderRadius: 4,
      fontSize: '16px !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: '#ECEFF1 !important',
      borderColor: '#B0BEC5 !important',
      color: '#546E7A !important',
      margin: '0 0 8px'
    },

    '& .chk-modal-actions': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '& .chk-pix-button#generate-new-pix-button, & .chk-pix-button.chk-pix-copy-button-text#change-payment-method-button': {
      maxWidth: 250,

      '@media only screen and (max-width: 960px)': {
        maxWidth: '100%'
      }
    },

    '& .chk-pix-copy-button, & .chk-pix-button': {
      backgroundColor: '#0D47A1',
      color: 'white',
      textTransform: 'none',
      fontSize: 16,

      '& svg': {
        marginRight: 8,
        fill: 'white'
      },

      '&.chk-pix-copy-button-text': {
        color: '#0D47A1',
        background: 'transparent',

        '& svg': {
          fill: '#0D47A1'
        }
      }
    },

    '& #change-payment-method-button': {
      marginTop: 16
    },

    '& .chk-pix-instructions-text': {
      marginBottom: 8
    },

    '& .chk-pix-modal-footer': {
      textAlign: 'right',
      color: '#546E7A',
      fontWeight: 600,

      '& span': {
        cursor: 'pointer'
      }
    },

    '& .chk-pix-divider': {
      width: '100%',
      height: '1px',
      margin: '24px 0',
      backgroundColor: '#ECEFF1'
    },

    '& .chk-pix-instructions-title': {
      margin: '0 0 16px',
      fontSize: 16
    },

    '& .chk-pix-qr-code-container': {
      width: 144,
      height: 144,
      backgroundColor: '#ECEFF1',
      border: '1px solid #546E7A',
      borderRadius: 8,
      marginLeft: 16,
      padding: 8,

      '& img': {
        width: '100%',
        height: '100%'
      }
    },

    '& .chk-pix-instructions-container.chk-pix-instructions-container-qrcode': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    }
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: '10px'
  },
  iconComplete: {
    width: '150px',
    height: '150px',
    fill: '#21f369',
    animationName: 'pixComplete',
    animationDuration: '800ms',
    animationTimingFunction: 'ease-out',
    animationDirection: 'forwards'
  }
});
