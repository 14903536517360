import * as qrcode from 'qrcode';
import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Hidden, Modal } from '@material-ui/core';
import { DesktopPixPaymentPanel } from './PixPaymentPanel/DesktopPixPaymentPanel';
import { MobilePixPaymentPanel } from './PixPaymentPanel/MobilePixPaymentPanel';
import { TimerPixExpiredPanel } from './PixPaymentPanel/TimerPixExpiredPanel';

import { enStatusWaiter } from 'enums/enStatusWaiter';
import { IWaiter, IResultWaiter } from 'services/PaymentWaiterService';
import { useStyles } from './styles';

interface IProps {
  waiter: IWaiter & IResultWaiter;
}

const PixModal: React.FC<IProps> = ({ waiter }) => {
  const styles = useStyles({});
  const [qrcodeUrl, setQrCodeUrl] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    const url = waiter.result.url;

    qrcode?.toDataURL(url, { margin: 0, width: 170 }).then((encoded) => {
      setQrCodeUrl(encoded);
    });
  }, [waiter]);

  useEffect(() => {
    const status = waiter.status;
    if (status === enStatusWaiter.CANCELED || status === enStatusWaiter.PAID) {
      setIsOpen(false);
    }
  }, [waiter]);

  if (!qrcodeUrl) {
    return null;
  }

  return (
    <Modal aria-labelledby="Modal de pagamento PIX" aria-roledescription="Modal de pagamento PIX" open={isOpen} id="pix-modal-id">
      <div className={styles.component}>
        <div className="chk-pix-panel" id="pix-panel">
          <div className="chk-payment-painel pix">
            <div className="chk-pix-data">
              {waiter.status === enStatusWaiter.RUNNING && (
                <>
                  <Hidden mdUp>
                    <div className={styles.component}>
                      <MobilePixPaymentPanel />
                    </div>
                  </Hidden>
                  <Hidden smDown>
                    <div className={styles.component}>
                      <DesktopPixPaymentPanel />
                    </div>
                  </Hidden>
                </>
              )}

              {waiter.status === enStatusWaiter.PAID && (
                <div id="pix-complete-panel" className="pixComplete chk-payment-painel pix chk-pix-modal-container">
                  <CheckCircleIcon className={styles.iconComplete} />
                </div>
              )}

              {waiter.status === enStatusWaiter.WAITING && <TimerPixExpiredPanel />}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(PixModal);
