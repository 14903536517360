import { enParameters } from 'enums/enParameters';
import * as queryString from 'query-string';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { IS_ECOMMERCE } from 'settings';
import { Cookies } from 'react-cookie';
import { checkLocalStorage } from 'functions';

export interface IParameters {
  [key: string]: any;
}

export class ParameterService {
  private parameters$: BehaviorSubject<IParameters>;
  private qsParameters: queryString.ParsedQuery<string>;

  constructor() {
    const cookie = new Cookies();

    this.qsParameters = queryString.parse(window.location.search);

    const parameters: IParameters = Object.keys(enParameters).reduce((acc, p) => {
      try {
        const temp = checkLocalStorage() ? window.localStorage.getItem(p) : null;
        acc[p] = this.qsParameters[p] || temp || null;
      } catch (err) {
        acc[p] = this.qsParameters[p] || null;
      }
      if (Array.isArray(acc[p])) {
        const validAttr = acc[p].filter((value: string) => !!value);
        acc[p] = validAttr[0];
      }

      return acc;
    }, {});

    parameters.utmSource = `${this.qsParameters.utm_source || ''}` || null;
    parameters.utmMedium = `${this.qsParameters.utm_medium || ''}` || null;
    parameters.utmCampaign = `${this.qsParameters.utm_campaign || ''}` || null;
    parameters.utmContent = `${this.qsParameters.utm_content || ''}` || null;
    parameters.terminalId = `${this.qsParameters.terminalId || ''}` || parameters.terminalId || null;
    parameters.a = `${this.qsParameters.a || ''}` || null;
    parameters.he = `${this.qsParameters.he || ''}` || cookie.get('he') || null;
    parameters.ec = this.qsParameters.ec || IS_ECOMMERCE || null ? '1' : null;
    parameters.email = parameters.email || cookie.get('email') || null;
    parameters.changeCard = Boolean(this.qsParameters.changeCard) || null;
    parameters.lastKey = cookie.get('lastKey') || null;
    parameters.templateId = Number(this.qsParameters.templateId) ?? null;
    parameters.enableFingerprintFailed = Boolean(this.qsParameters.enableFingerprintFailed) || null;

    if (parameters.u === '1' && cookie.get('lastPaymentMethod') === 'bankslip') {
      parameters.upsell = '1';
    }

    this.parameters$ = new BehaviorSubject(parameters);
  }

  public get(key: enParameters): Observable<string> {
    return this.getAll()
      .map((parameters) => parameters[key] || null)
      .distinctUntilChanged();
  }

  public setParameter(key: enParameters, value: string): void {
    const parameters = this.parameters$.value;
    parameters[key] = value;
    this.parameters$.next(parameters);
  }

  public getSync(key: enParameters): string {
    const parameters = this.parameters$.value;
    return parameters[key] || null;
  }

  public getAll(): Observable<IParameters> {
    return this.parameters$.asObservable().map((parameters) => {
      return Object.keys(parameters).reduce((acc, i) => {
        if (parameters[i] !== null) {
          acc[i] = parameters[i];
        }
        return acc;
      }, {});
    });
  }
}

// tslint:disable-next-line:variable-name
export const ParameterServiceFactory = () => new ParameterService();

export const parameterService = ParameterServiceFactory();
