export enum enParameters {
  // User Parameters
  cel = 'cel', // preenche telefone do cliente
  df = 'df', // documento de pessoa física, apenas aceita CPF
  dj = 'dj', // documento de pessoa jurídica, apenas aceita CNPJ
  doc = 'doc', // preenche o documento do cliente
  email = 'email', // email do cliente
  name = 'name', // preenche nome do cliente
  nome = 'nome', // preenche nome do cliente
  phone = 'phone', // preenche telefone do cliente
  sdoc = 'sdoc', // Exibe campo de documento para compras nacionais (CPF/CNPJ) para produtos gratuitos

  //User Config Parameters
  samePerson = 'samePerson', // Parâmetro para definir se o aluno é a mesma pessoa que o comprador

  // User Address Parameters
  comp = 'comp', // preenche complemento do endereço do cliente
  num = 'num', // preenche número do endereço do cliente
  cep = 'cep', // ja preenche o cep
  zip = 'zip', // Preenche Zip Code do cliente
  city = 'city', // preenche cidade do cliente
  street = 'street', // preenche rua do cliente
  state = 'state', // preenche estado do cliente
  location = 'location', // preenche país do cliente (Usar id do país na função getCountries)
  district = 'district', // preenche bairro do cliente

  // Student Parameters
  studentName = 'studentName', // preenche nome do aluno
  studentEmail = 'studentEmail', // preenche email do aluno
  studentDocument = 'studentDocument', // preenche documento do aluno
  studentPhone = 'studentPhone', // preenche telefone do aluno

  // Select Country Parameters
  country = 'country', // Seleciona um país específico

  // Payment Config Parameters
  cp = 'cp', // número mínimo de parcelas
  np = 'np', // número máximo de parcelas
  p = 'p', // vem com uma parcela selecionada
  pe = 'pe', // número exato de parcelas
  pf = 'pf', // obriga o cliente a selecionar o numero de parcelas, ou seja, não vem selecionada nenhuma parcela por padrão
  pu = 'pu', // Parcela única, venda somente à vista. Seta como a vista, mas diferente do "p=1" o usuário não pode trocar a parcela.
  pr = 'pr', // inverte ordem de parcelas
  changeCard = 'changeCard', // Troca de cartão para recuperação de assinatura
  cupom = 'cupom', // Para aplicar cupons

  // Payment Parameters
  b = 'b', // exibe ou esconde a opção de pagar com boleto
  bs = 'bs', // Gera um novo boleto
  dd = 'dd', // seta a data de vencimento do boleto
  dv = 'dv', // muda o numero de dias do vencimento da fatura
  lastKey = 'lastKey', // key da transação anterior usada para o upsell de boleto
  upsell = 'upsell', // upsell boleto

  // UPSell Parameters
  u = 'u', // upsell
  ukey = 'ukey', // chave do upsell

  // Checkout Config Parameters
  templateId = 'templateId', // id do template
  skip = 'skip', // Pula preenchimento de dados do cliente
  terminalId = 'terminalId', // Parâmetro com a idenficação da maquininha de pagamento
  config = 'config', // Parâmetro para aplicar configurações de template no checkout
  currency = 'currency', // Abre o checkout com a moeda passada via parâmetro

  // UTM Parameters
  utmCampaign = 'utmCampaign', // exemplo: ?utm_campaign=campanha1 -> isso mapeia por meio de onde o produto foi vendido
  utmContent = 'utmContent', // exemplo: ?utm_content=conteudo12 -> isso mapeia por meio de onde o produto foi vendido
  utmMedium = 'utmMedium', // exemplo: ?utm_medium=medium1     -> isso mapeia por meio de onde o produto foi vendido
  utmSource = 'utmSource', // exemplo:  ?utm_source=facebook   -> isso mapeia por meio de onde o produto foi vendido

  //Eduzz Parameters
  trk = 'trk', // Tem o mesmo intuito das Utms, porém, é interno da eduzz
  trk2 = 'trk2', // Tem o mesmo intuito das Utms, porém, é interno da eduzz
  trk3 = 'trk3', // Tem o mesmo intuito das Utms, porém, é interno da eduzz

  // Disabled Parameters
  c = 'c', // Este Parâmetro está Desativado
  ec = 'ec', // Este Parâmetro está Desativado
  info = 'info', // Este Parâmetro está Desativado
  pm = 'pm', // Este Parâmetro está Desativado
  pxidfb = 'pxidfb', // pixel do facebook (no momento está desativado)
  pxvlfb = 'pxvlfb', // pixel do facebook (no momento está desativado)
  session = 'session', // Este Parâmetro está Desativado
  sid = 'sid', // Este Parâmetro está Desativado
  wd = 'wd', // Este Parâmetro está Desativado

  // QA Parameters
  ignoreCaptcha = 'ignoreCaptcha', //parametro para ignorar o captcha, usado para automação
  qaerror = 'qaerror', // Parâmetro força o uso de um erro específico
  qaflow = 'qaflow', // Parâmetro ainda desconhecido, caso tenha informações atualiza aqui pra gente =]
  qaparam = 'qaparam', // Para forçar o uso de um PSP específico

  // RVP Parameters
  fpg = 'fpg', // "pix": gera pagamento via Pix automaticamente no fluxo do RVP

  // Other Parameters
  he = 'he', // OCB
  a = 'a', // id do afiliado
  action = 'action', // parâmetro para uso da recuperação
  enableFingerprintFailed = 'enableFingerprintFailed'
}
